.main-menu ul > li .sub-menu {
  width: 300px;
}
.breadcrumbs2 {
  background: #f5f5f5;
  border-bottom: 1px solid #d7d7d7;
  position: sticky;
  top: 128px;
  z-index: 9;
  @media (max-width: 1024px) {
    top: 120px;
  }
  @media (max-width: 767px) {
    top: 59px;
  }
  ul {
    display: flex;
    li {
      display: inline-block;
      font-weight: 500;
      font-size: 14px;
      line-height: 15px;
      position: relative;
      @media (max-width: 767px) {
        &:first-child {
          display: none;
        }
        &:nth-child(2) {
          padding-left: 0;
          &:before {
            display: none;
          }
        }
      }
      a {
        display: block;
      }
      + li {
        padding-left: 40px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis; 
        max-width: 70%; 
        &:before {
          background: #000;
          border-radius: 50%;
          content: "";
          height: 4px;
          left: 17px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 4px;
        }
      }
    }
  }
}
// slider 
.tp-slider .swiper-slide-active .slider-content span {
  animation-delay: 0.3s;
  animation-duration: 0.3s;
}
.tp-slider .swiper-slide-active{
  & .slider-content__title,
  & p, & span,
  & .slider-content__btn{
      animation-fill-mode: both;
      animation-name: fadeInUp;
  }
}
.slider-content {
  margin-left: 0;
  &__title {
    font-size: 47px;
  }
  &__bg {
    display: block;
    position: relative;
    margin-top: 100px;
    margin-right: 0;
    margin-left: auto;
    width: 95%;
    @media (max-width: 768px) {
      margin-bottom: 30px;
    }
    &:before {
      content: '';
      display: table;
    }
    &:after {
      content: '';
      clear: both;
      display: table;
    }
    > img {
        display: block;
        float: left;
        margin: 0 9px 18px;
        object-fit: cover;
        object-position: top center;
        @media (max-width: 767px) {
          margin: 0 5px 10px;
        }
      &:first-child {
        height: auto;
        width: 305px;
        @media (max-width: 1024px) {
          height: 309px;
        }
        @media (max-width: 768px) {
          height: 420px;
        }
        @media (max-width: 767px) {
          height: 230px;
        }
      }
      &:nth-child(2) {
        height: 173px;
        width: 240px;
        @media (max-width: 1024px) {
          height: 150px;
        }
        @media (max-width: 768px) {
          height: 200px;
        }
        @media (max-width: 767px) {
          height: 110px;
        }
      }
      &:nth-child(3) {
        height: 200px;
        width: 240px;
        @media (max-width: 1024px) {
          height: 140px;
        }
        @media (max-width: 768px) {
          height: 200px;
        }
        @media (max-width: 767px) {
          height: 110px;
        }
      }
    }
  }
}
// accordion
.footer-widget {
  h5 {
    color: #fff;
  }
}
.accordion {
  margin-top: 30px;
  .accordion-box {
    margin-bottom: 20px;
    &.active .accordion-head::after {
      content: url('../img/icon/top-arrow.png');
    }
    .accordion-head {
      cursor: pointer;
      padding-right: 30px;
      position: relative;
      &::after {
        bottom: 0;
        content: url('../img/icon/down-arrow.png');
        position: absolute;
        right: 0;
        top: 0;
        transform: scale(.4) translateY(-50%);
        transform-origin: right center;
      }
      h5 {
        color: #fff;
        margin-bottom: 0;
      }
    }
    .accordion-body {
      display: none;
      padding: 10px 0 0;
    }
  }
}
// Popup
.popup {
  background: rgba(0, 0, 0, 0.9);
  display: none;
  align-items: center;
  overflow: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999999;
	&.small .inner-popup {
		width: 565px;
	}
	&.medium .inner-popup {
		width: 720px;
	}
  &.with-space .inner-popup {
    margin-bottom: 100px;
    top: 50px;
  }
  .close-popup, .closety {
    background: url('../img/icon/icon-close.svg') no-repeat center center; 
  	cursor: pointer; 
    height: 18px;
  	position: absolute;
    right: 24px;
    top: 24px;
  	transition: all .3s ease-out;
    width: 18px;
    z-index: 9;
  	&:hover {
  		transform: rotate(180deg);
  	}
  }   
	.inner-popup {
    box-sizing: border-box; 		
		margin: auto;
    overflow: hidden;	 
		position: relative; 		
		width: 920px;
		z-index: 3;
    &.medium{
      width: 720px;
    }
    &.small{
      width: 540px;
      height: auto;
      overflow: unset;
      padding: 0;
    }
    &.xssmall{
      width: 510px;
      // height: 735px;
      padding: 0;
      .content-popup{
        padding: 40px 0px;
      }
      &.payment{
        .content-popup{
          padding: 0;
          .title{
            text-align: right;
            padding: 30px;
            border-bottom: 1px solid #E6E6E6;
            margin: 0;
            h6{
              font-weight: 500;
            }
          }
        }
      }
    }
	}
  .content-popup {
		background: #fff;
    border-radius: 12px;
    display: flex;	
		flex-direction: column; 
		position: relative;
    padding: 50px 40px;
    img {
      height: auto;
      margin: 0 auto 31px;
      display: block;
      width: auto;
    }
	}	
	.overlay-popup {
		margin: auto;
		z-index: 2;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.5);
		cursor: pointer; 
	}
  .download-popup {
    form {
      margin-top: 20px;
      .form-group {
        label {
          font-weight: 600;
          margin-bottom: 8px;
        }
        input {
          border: 1px solid #d7d7d7;
          margin-bottom: 20px;
          padding: 10px 15px;
          width: 100%;
        }
        .tp-btn {
          margin-bottom: 0;
          padding: 15px;
        }
      }
    }
    .notes {
      color: red;
      display: block;
      font-size: 16px;
      font-style: italic;
      margin-bottom: 16px;
    }
  }
}

// General
body.lock {
  overflow: hidden !important;
}
.bannerscroll {
  display: none !important;
}
.slider-social {
  bottom: unset;
  top: 50%;
  transform: translateY(-50%);
}
.header-menu-top,
.header-menu-bottom {
  .row {
    margin: 0;
    > div:first-child {
      padding-left: 0;
    }
    > div:last-child {
      padding-right: 0;
    }
  }
}
.slider-content span {
  display: inline-block;
  font-family: var(--tp-ff-heading);
  font-size: 20px;
  font-weight: normal;
  padding: 10px 20px;
  color: var(--tp-theme-blue);
  background-color: var(--tp-icon-blue-light);
  text-transform: uppercase;
  border-radius: 5px;
  text-transform: capitalize;
  margin-bottom: 20px;
  transition: all 0.3s ease-out 0s;
}
.gallery-item:hover.gallery-item::before {
  height: 170px;
}
.gallery-item__content {
  padding: 20px 20px 10px;
}
.gallery-item__title {
  font-size: 16px;
  margin-bottom: 0;
  a {
    margin-bottom: 0;
  }
}
.brand-items .swiper-slide img {
  margin-bottom: 20px;
}
.services-area .services-item__tp-title {
  font-size: 18px;
}
.main-menu-second ul li > a::before,
.main-menu-second ul li > a::after {
  display: none;
}
.header-time span {
  font-size: 14px;
  text-transform: unset;
}
.main-menu-second,.header-top-mob {
  margin: 0 0 0 60px;
}
.breadcrumb__area {
  &.breadcrumb__overlay {
    background-size: cover;
    background-position: center;
  }
  .tp-breadcrumb {
    &__links {
      margin-bottom: 20px;
      li {
        color: rgba(255, 255, 255, 0.7);
        display: inline-block;
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        a {
          color: var(--tp-common-white);
        }
        + li {
          margin-left: 15px;
          padding-left: 30px;
          position: relative;
          &::before {
            content: url('../img/icon/icon-arrow-breadcrumbs.svg');
            left: 3px;
            top: 0;
            position: absolute;
          }
        }
      }
    }
    &__title {
      text-transform: capitalize;
    }
  }
}
.bg-blue-light {
  background: var(--tp-icon-blue-light);
}
.contactform .nice-select {
  border: 1px solid var(--tp-border-primary);
  border-radius: 4px;
  box-shadow: 0px 16px 24px rgba(189,196,205,.13);
  color: var(--tp-heading-primary);
  font-size: 16px;
  height: 60px;
  line-height: 60px;
  float: none;
  outline: 0;
  padding: 0 20px;
  width: 100%;
  &.open .list {
    width: 100%;
  }
}
.back-link {
  align-items: center;
  display: flex;
  font-weight: 600;
  &:hover {
    color: var(--tp-icon-blue);
    svg path {
      fill: var(--tp-icon-blue);
    }
    img, svg {
      left: -3px;
      transition: .2s all ease;
    }
  }
  img, svg {
    margin-right: 10px;
    position: relative;
    transition: .2s all ease;
  }
}
.content-pages {
  h1,h2,h3,h4,h5,h6 {
    margin-bottom: 20px;
  }
  ul, ol {
    margin-bottom: 20px;
  }
  img {
    display: block;
    margin: 0 auto 30px;
    width: 100%;
  }
  ul {
    padding: 0;
    li {
      padding-left: 30px;
      position: relative;
      &::before {
        background: #000;
        border-radius: 50%;
        content: '';
        left: 10px;
        height: 5px;
        position: absolute;
        top: 11px;
        width: 5px;
      }
    }
  }
  ol {
    counter-reset: li;
    padding: 0;
    li {
      counter-increment: li;
      padding-left: 30px;
      position: relative;
      &::before {
        content: counter(li) ".";
        left: 10px;
        height: 5px;
        position: absolute;
        top: 0px;
        width: 5px;
      }
    }
  }
  p {
    margin-bottom: 20px;
  }
}
.file {
  border-radius: 4px;
  border: 1px solid var(--tp-border-primary);
  box-shadow: 0px 16px 24px #bdc4cd21;
  color: var(--tp-heading-primary);
  font-size: 16px;
  height: 60px;
  outline: 0;
  padding: 0;
  position: relative;
  width: 100%;
  input[type="file"] {
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  }
  span {
    display: block;
    padding: 16px 20px;
    position: relative;
    i {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
}
.tp-btn, .tp-btn-second {
  padding: 20px 45px;
}
.contactform__input label {
  margin-bottom: 5px;
}
.floating-sosmed {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  top: 40%;
  transform: translateY(-50%);
  z-index: 99;
  li {
    a {
      align-items: center;
      background: #ddd;
      border-bottom: 1px solid transparent;
      display: flex ;
      height: 50px;
      float: right;
      justify-content: center;
      transition: .2s all ease;
      width: 50px;
      &.facebook {
        background: rgba(25, 118, 210, 0.1);
      }
      &.instagram {
        background: rgba(253, 0, 86, 0.1);
      }
      &.linkedin {
        background: rgba(0, 119, 181, 0.1);
      }
      &:hover {
        transition: .2s all ease;
        width: 60px;
      }
    }
  }
}
.floating-wa{
  background-color:#25d366;
	border-radius:50px;
	box-shadow: 2px 2px 3px #999;
	bottom:40px;
	color:#FFF;
  display: flex;
  font-size:30px;
	position:fixed;
	right:40px;
	text-align:center;
  z-index:100;
  a {
    align-items: center;
    display: flex;
    height:60px;
    justify-content: center;
    width:60px;
  }
}
.scroll-top.open {
  display: none;
}
footer {
  .footer-widget p {
    margin-bottom: 0;
  }
  .footer-widget .img {
    align-items: center;
    display: flex;
    img {
      display: inline-block;
      margin-right: 32px;
    }
  }
  .footer-widget__sitemap {
    text-align: right;
    a {
      color: var(--tp-common-white);
    }
  }
  .footer-widget__copyright br {
    display: none;
  }
  .maps-foot {
    iframe {
      width: 100%;
    }
  }
}

// Home Pages
.bannerscroll {
  top: -80px;
  .bannerscroll-icon {
    cursor: pointer; 
  }
}
.slider-content__arrow {
  bottom: unset;
  left: unset;
  right: unset;
  position: unset;
  .slider-p {
    left: 80px;
    position: absolute;
    top: -40px;
  }
  .slider-n {
    right: 80px;
    position: absolute;
    top: -40px;
  }
}
.slider-content__arrow i {
  background: #fff;
}
.slider-area {
  overflow: hidden;
  position: relative;
  &::before {
    bottom: -8px;
    background: url('../img/icon/bg-bottom.png') no-repeat center;
    background-size: cover;
    content: '';
    left: 0;
    height: 180px;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: -1;
  }
}
.slider-content {
  &__bg img {
    display: block;
    width: 100%;
  }
  &__shape {
    position: absolute;
    right: 175px;
    top: 375px;
  }
}
.about-video {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .fa-play::before{
    left: 1px;
    position: relative;
    zoom: 2;
  }
}
.about-area {
  .about-thumb {
    height: 620px;
    img {
      display: block;
      height: 100%;
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
  }
  .about-content {
    &:hover .btn-hexa i {
      background-color: var(--tp-icon-sky);
    }
    &__btn {
    .btn-hexa {
      font-size: 14px;
      font-weight: 700;
      text-transform: capitalize;
      text-transform: uppercase;
      &:hover {
        color: var(--tp-icon-sky);
        i {
          background-color: var(--tp-icon-sky);
        }
      }
      & i {
        clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
        background-color: var(--tp-icon-blue-light);
        display: inline-block;
        height: 20px;
        width: 18px;
        transform: translateY(5px);
        transition: .3s all ease;
        flex: 0 0 auto;   
        margin-right: 10px;    
      }
    }
  }
  }
}
.services-area {
  .services-item {
    display: block;
    cursor: pointer;
    margin: 0 0 40px;
    text-align: center;
    width: 100%;
    &:hover {
      .services-item__icon {
        .icon-primary {
          display: none;
        }
        .icon-secondary {
          display: block;
          opacity: 1;
          visibility: visible;
        }
      }
    }
    &__icon {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 0 auto 30px;
      img, svg {
        display: block;
        height: auto;
        margin: 0 auto;
        width: 36px;
      }
      .icon-secondary {
        display: none;
        opacity: 0;
        transition: .2s all ease;
        visibility: hidden;
      }
    }
    &__content p {
      margin-bottom: 30px;
    }
  }
}
@media (min-width: 1190px) {
  .blog-area {
    .swiper-wrapper {
      margin: 0 -15px;
      width: calc(100% + 15px);
      .swiper-slide {
        flex: 0 0 33.33%;
        max-width: 33.33%;
        padding: 0 15px;
      }
    }
  }
  .blog-arrow {
    display: none !important;
  }
}
@media (min-width: 767px) {
  footer {
    .footer-top .container {
      padding: 0;
    }
  }
}
// About Pages
.tp-ab-shape {
  .ab-shape-one,.ab-shape-two {
    width: 100%;
  }
}
.about-align {
  padding-left: 50px;
}
.blog-area.bg-blue-light {
  .blog-item {
    background: var(--tp-common-white);
  }
}

// About Visimi
.visimi-area {
  .tp-visimi {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    .visimi-title h2 {
      display: inline-block;
      position: relative;
      &::before {
        background: #0E63FF;
        bottom: -5px;
        content: '';
        height: 1px;
        position: absolute;
        right: -35px;
        width: 137px;
      }
      &::after {
        background: #0E63FF;
        bottom: -25px;
        content: '';
        height: 47px;
        position: absolute;
        right: -10px;
        width: 1px;
      }
    }
    .visimi-content h5 {
      font-family: var(--tp-ff-body);
    }
  }
}

// About Organization
.organization-area {
  .row {
    justify-content: center;
  }
  .organization-item {
    border-radius: 4px;
    height: 100%;
    margin-bottom: 30px;
    overflow: hidden;
    &.highlight {
      span {
        display: block;
        margin-bottom: 30px;
      }
    }
    img {
      display: block;
      margin: 0 auto;
      width: 100%;
    }
    figcaption {
      background: #ECEEFE;
      padding: 34px 30px;
      h4 {
        font-size: 16px;
        line-height: normal;
        margin-bottom: 10px;
      }
      span {
        display: block;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

// About Certificate
.list-download {
  margin-top: 50px;
  .download {
    background: #E7FAF6;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 22px 25px;
    width: 100%;
    span {
      display: block;
      flex: 1 1 auto;
      font-size: 14px;
      font-weight: 400;
      line-height: 15px;
      padding-right: 30px;
    }
    a {
      align-items: center;
      display: flex;
      cursor: pointer;
      flex: 0 0 auto;
      font-size: 14px;
      font-weight: bold;
      line-height: 15px;
      transition: .2s all ease;
      width: max-content;
      &:hover {
        color: var(--tp-icon-blue);
        transition: .2s all ease;
      }
      i {
        margin-left: 10px;
      }
    }
  }
}

// News Detail
.services-details {
  .services-thumb-img {
    img {
      display: block;
      height: auto;
      width: 100%;
    }
  }
}

// Gallery 
.research-area .research-item {
  &__all {
    display: none;
  }
  &__btn {
    cursor: pointer;
  }
  &__thum img {
    display: block;
    height: auto;
    width: 100%;
  }
}

// Career
.career-area {
  &.with-borderbot .container {
    border-bottom: 4px solid #0E63FF;
    padding-bottom: 80px;
  }
  .career-item {
    background: #FFFFFF;
    border: 1px solid #F2F5FA;
    box-shadow: 0px 10px 10px rgba(228, 228, 228, 0.25);
    padding: 48px 42px;
    &__content {
      p {
        margin-bottom: 38px;
      }
      span {
        display: block;
      }
    }
    &__btn {
      background-color: var(--tp-icon-blue-light);
      border-radius: 5px;
      color: var(--tp-theme-blue);
      display: inline-block;
      font-size: 14px;
      font-weight: 700;
      margin-top: 41px;
      padding: 5px 20px;
      text-transform: uppercase;
      &:hover {
        background-color: var(--tp-theme-blue);
        color: var(--tp-common-white);
      }
    }
  }
  .back-link {
    margin-top: 70px;
  }
}

// Services Detail
.services-details-area {
  aside {
    ul {
      li {
        margin: 11px 0;
        &.dropdown {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          // padding-right: 30px;
          position: relative;
          &.activated {
            > i::before {
              content: "\f068";
            }
          }
          > i {
            cursor: pointer;
            font-size: 18px;
            font-weight: bold;
            position: absolute;
            right: 0;
            top: 6px;
          }
          > ul {
            display: none;
            margin-top: 5px;
            padding-left: 30px;
            width: 100%;
            li {
              &.active > a, &.activated > a {
                color: var(--tp-icon-blue);
                // font-weight: bold;
              }
              a {
                color: var(--tp-text-body);
                display: inline-block;
                font-size: 15px;
                font-weight: normal;
                line-height: 30px;
                transition: .2s all ease;
                width: 90%;
                &:hover {
                  color: var(--tp-icon-blue);
                  transition: .2s all ease;
                }
              }
            }
          }
        }
        &.active {
          > a {
            color: var(--tp-icon-blue);
          }
        }
        > a {
          color: var(--tp-text-body);
          cursor: pointer;
          display: block;
          font-size: 18px;
          font-weight: 700;
          line-height: 30px;
          width: 100%;
        }
      }
    }
  }
}

// 404
.notfound__area {
  &.undermaintenance {
    height: 100vh;
    width: 100%;
    img {
      display: block;
      margin: 0 auto 72px;
      position: relative;
      top: -100px;
    }
    h2 {
      color: var(--tp-common-white);
      font-size: 80px;
      font-weight: 400;
      line-height: 102px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-bottom: 32px;
    }
    a {
      text-decoration: underline;
      transition: .2s all ease;
      &:hover {
        color: var(--tp-icon-blue);
        transition: .2s all ease;
      }
    }
  }
  &.notfound__overlay {
    background-size: cover;
    background-position: center;
    padding: 200px 0;
    position: relative;
    z-index: 1;
    &::after {
      background-color: rgba(23, 17, 81, 0.7);
      content: "";
      left: 0;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }
  }
  .justify-content-center {
    justify-content: center;
    text-align: center;
  }
  h2 {
    color: var(--tp-common-white);
    font-size: 125px;
    font-weight: 400;
    line-height: 159px;
    letter-spacing: 0.1em;
    margin-bottom: 0;
  }
  h3 {
    color: var(--tp-common-white);
    font-weight: 400;
    font-size: 35px;
    line-height: 45px;
    letter-spacing: 0.1em;
    margin-bottom: 20px;
  }
  p {
    color: var(--tp-common-white);
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 38px;
  }
}

@media (max-width: 1480px) {
  .slider-content__bg {
    margin-top: 60px;
  }
  .slider-content__arrow {
    .slider-p {
      left: 20px;
    }
    .slider-n {
      right: 20px;
    }
  }
}

@media (max-width: 1366px) {
  .floating-sosmed {
    top: 34%;
  }
  .swiper-slide .row {
    > .col-lg-6.order-lg-1 {
      flex: 0 0 45%;
      max-width: 45%;
    }
    > .col-lg-6.order-lg-2 {
      flex: 0 0 55%;
      max-width: 55%;
    }
  }
  .slider-content__title {
    font-size: 34px;
  }
  .slider-content__bg {
    width: 94%;
  }
}

@media (max-width: 1280px) {
  .slider-social {
    bottom: 60px;
    top: unset;
    transform: unset;
    left: 60px;
    .slider-p, .slider-n {
      left: unset;
      position: unset;
      top: unset;
    }
  }
}

@media (max-width: 1024px) {
  .slider-content span {
    font-size: 18px;
  }
  .slider-social {
    left: 45px;
  }
  .about-area .about-thumb {
    height: 450px;
  }
  .slider-content__bg {
    margin-top: 30px;
    > img {
      width: 45% !important;
    }
  }
  .slider-content {
    padding-top: 0;
  }
}

@media (max-width: 991px) {
  .slider-content span {
    font-size: 16px;
  }
  .slider-social {
    bottom: 30px;
    left: 40px;
  }
  .swiper-slide .row > .col-lg-6.order-lg-1,
  .swiper-slide .row > .col-lg-6.order-lg-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .slider-content {
    margin-bottom: 40px;
    &__bg {
      margin: 30px auto 30px;
      width: 100%;
      > img {
        width: calc(50% - 20px) !important;
      }
    }
  }
}

@media (max-width: 767px) {
  .slider-content__bg > img {
    width: calc(50% - 10px) !important;
  }
  .slider-content span {
    font-size: 14px;
    padding: 8px 20px;
  }
  .slider-social {
    left: 0;
    bottom: 60px;
    right: 0;
  }
  .slider-content__arrow {
    left: unset;
    transform: unset;
    text-align: center;
    justify-content: center;
  }
  .slider-content__bg {
    margin: 0 auto 10px;
  }
  .slider-content__title {
    font-size: 21px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .about-content .tp-section p {
    padding: 0;
  }
}

@media (min-width: 1500px) and (max-width: 1680px) {
  .bannerscroll {
    left: 40px;
  }
  .slider-content__arrow {
    left: 150px;
  }
  .slider-content__shape {
    right: 85px;
    top: 405px;
  }
}

@media (max-width: 1480px) {
  .header-logo-box {
    width: 300px;
  }
  .bannerscroll {
    display: none !important;
  }
  .slider-tp-top {
    height: calc(100vh - 128px);
  }
  .slider-content {
    &__shape {
      right: 65px;
      top: 395px;
      img {
        width: 190px;
      }
    }
  }
}

@media (max-width: 1366px) {
  .slider-content {
    &__title {
      font-size: 47px;
    }
    &__bg img {
    }
    &__shape {
      right: 70px;
      top: 295px;
      img {
        width: 150px;
      }
    }
  }
}

@media (min-width: 1280px) and (max-width: 1366px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1190px;
  }
}

@media (max-width: 1440px) {
  .slider-tp-top {
    height: auto;
    padding-bottom: 80px;
  }
}

@media (max-width: 1280px) {
  .floating-sosmed {
    top: 30%;
  }
}

@media (max-width: 1190px) {
  .services-details-area {
    padding-top: 60px;
    aside {
      margin-bottom: 60px;
    }
  }
  .tp-mobile-header-area {
    border-bottom: 1px solid #d7d7d7;
  }
  .breadcrumb__area {
    padding: 150px 0;
  }
  .tp-breadcrumb__title {
    font-size: 38px;
  }
  .tp-mob-logo img {
    width: auto;
  }
  .tpsideinfo {
    padding: 30px 40px;
    width: 480px;
    &__logo a img {
      width: auto;
    }
    &__content > * {
      color: var(--tp-text-2) !important;
    }
  }
  .slider-area::before {
    z-index: -1;
  }
  .banner__box-item {
    .row {
      .col-xs-6,.col-lg-6 {
        width: 100%;
      }
    }
  }
  .services-details {
    padding-top: 80px;
  }
  .slider-content {
    &__title {
      font-size: 38px;
    }
    &__bg img {
      width: 85%;
    }
    &__shape {
      right: 50px;
      top: 355px;
    }
  }
  .about-area.grey-bg {
    padding-bottom: 100px;
    padding-top: 0;
    .about-thumb {
      margin: 0 -15px 60px;
    }
    .about-content {
      margin: 0 60px;
    }
  }
  .organization-area .organization-item figcaption h4 {
    font-size: 18px;
    margin-bottom: 5px;
  }
  .gallery-area .container {
    width: 90%;
  }
  .fw-border {
    border-bottom: none;
  }
  footer {
    .row > div:last-child .footer-widget {
      margin-bottom: 0;
    }
    .footer-widget .lst-itm {
      margin-top: 40px;
      display: flex;
      .img {
        flex: 0 0 auto;
        width: 40%;
      }
      .info {
        flex: 0 0 auto;
        padding-left: 60px;
        width: 60%;
      }
    }
    .maps-foot {
      display: none;
    }
  }
  .scroll-top.open {
    bottom: 125px;
  }
}

@media (max-width: 991px) {
  .scroll-top.open {
    bottom: 70px;
  }
  .slider-content {
    &__bg img {
      width: 100%;
    }
    &__shape {
      right: 300px;
      top: 180px;
    }
  }
  .banner__box-item .row {
    .col-xs-6, .col-lg-6 {
      width: 50%;
    }
  }
  .about-area.grey-bg .about-content {
    margin: 0 40px;
  }
  .research-item__content,
  .blog-item__content {
    h5,h4 {
      line-height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    p {
      max-height: 52px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .organization-area .organization-item {
    margin-bottom: 40px;
    &.highlight figure {
      margin-bottom: 30px;
    }
  }
  .tp-srv-process__title {
    font-size: 32px;
    line-height: normal;
  }
  .career-area {
    .career-item {
      padding: 38px 32px;
    }
    .contactform {
      margin-top: 100px;
    }
  }
  .fw-border {
    border: none;
  }
  footer {
    .footer-widget__copyright {
      padding: 0 10px;
      span {
        font-size: 12px;
        line-height: 26px;
        margin: 0;
      }
    }
  }
}

@media (max-width: 767px) {
  .about-area.grey-bg {
    margin-top: 0;
  }
  .scroll-top.open {
    bottom: 70px;
  }
  .floating-sosmed {
    display: none;
  }
  .floating-wa {
    bottom: 20px;
    right: 20px;
    a {
      height: 50px;
      width: 50px;
    }
  }
  .slider-content {
    text-align: center;
    &__title {
      margin-bottom: 30px;
    }
    p {
      font-size: 16px;
      margin-bottom: 50px;
    }
    &__bg img {
      width: 50%;
    }
  }
  .tp-mob-logo img {
    width: 30px;
  }
  .tpsideinfo {
    padding: 30px;
    width: 100%;
    &__logo a img {
      width: 70px;
    }
  }
  .breadcrumb__area {
    padding: 100px 0;
    .tp-breadcrumb__links {
      margin-bottom: 10px;
      li {
        font-size: 12px;
      }
    }
  }
  .banner__box-item .row .col-xs-6, .banner__box-item .row .col-lg-6 {
    width: 100%;
  }
  .slider-content__title {
    font-size: 28px;
  }
  .services-item__content {
    padding-right: 40px;
  }
  .tp-breadcrumb__title {
    font-size: 24px;
  }
  .contact-area, .career-area, .research-area,
  .certificate-area, .organization-area, .visimi-area,
  .about-area, .services-area {
    padding: 80px 0;
  }
  .services-details-area {
    padding: 40px 0 80px;
  }
  .gallery-bg-title {
    padding-top: 80px;
  }
  .blog-area {
    padding: 60px 0;
  }
  .career-area.with-borderbot .container {
    border: none;
    padding-bottom: 0;
  }
  .career-area .contactform__list {
    margin-bottom: 0;
  }
  .tp-srv-process__title {
    font-size: 24px;
    margin-bottom: 0;
  }
  .tp-section__sub-title {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .tp-section__title {
    font-size: 21px;
    margin-bottom: 40px;
  }
  .about-area .about-thumb {
    height: 370px;
  }
  .about-area.grey-bg .about-content {
    margin: 0;
  }
  .about-content {
    padding-left: 0;
  }
  .blog-item__content p {
    font-size: 14px;
    line-height: 28px;
  }
  .visimi-area .tp-visimi {
    width: 100%;
    .visimi-title h2 {
      font-size: 24px;
    }
    .visimi-content h5 {
      font-size: 16px;
      line-height: 26px;
    }
  }
  .blog-arrow {
    display: none !important;
  }
  .list-download .download {
    flex-direction: column;
    span {
      margin-bottom: 20px;
      padding-right: 0;
    }
  }
  footer {
    .footer-area {
      padding-top: 60px;
    }
    .footer-widget__links ul li a,
    .footer-widget__links.smaller ul li a {
      font-size: 14px;
      line-height: normal;
    }
    .footer-widget .lst-itm {
      flex-direction: column;
      .info {
        padding-left: 0;
        width: 100%;
      }
    }
    .footer-widget {
      &__links.smaller .footer-widget__title, 
      &__title.smaller {
        font-size: 14px;
        line-height: 26px;
      }
      &__links.smaller .footer-widget__title {
        margin-bottom: 10px;
      }
      &__links.smaller ul li a,
      p {
        font-size: 12px;
        line-height: 26px;
      }
      &__copyright {
        padding: 0 10px;
        span {
          font-size: 12px;
          line-height: 26px;
          margin: 0;
          br {
            display: block;
          }
          > span {
            display: block;
            > span {
              display: inline-block;
            }
          }
        }
      }
    }
  }
  .fw-border {
    border-top: 1px solid var(--tp-border-primary);
    padding: 20px 0;
  }
  .footer-widget__copyright {
    padding: 0 20px;
  }
}